<template>
  <v-app>
    <div class="container-centered">
      <v-card class="mx-auto px-6 py-8" max-width="344">
        <v-form
          v-model="form"
          @submit.prevent="login"
        >
          <v-text-field
            v-model="username"
            :rules="[required]"
            class="mb-2"
            clearable
            label="Username"
          ></v-text-field>
  
          <v-text-field
            v-model="password"
            type="password"
            :rules="[required]"
            clearable
            label="Password"
            placeholder="Enter your password"
          ></v-text-field>
  
          <br />
  
          <v-alert v-if="loginError" type="error" class="mb-4">
            {{ loginError }}
          </v-alert>
  
          <v-btn
            :disabled="!form"
            block
            color="primary"
            size="large"
            type="submit"
            variant="elevated"
          >
            Login
          </v-btn>
        </v-form>
      </v-card>
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      form: false,
      username: '',
      password: '',
      loginError: '',
    }
  },
  methods: {
    async login() {
      try {
        await this.$store.dispatch('user/login', { username: this.username, password: this.password });
        this.$store.commit('global/setLoading', { loading: true });
        this.$router.push({ name: 'dashboard' });
      } catch (err) {
        if (err.response.status === 401) {
          this.loginError = 'Invalid username or password!';
        }
      }
    },
    required (v) {
      return !!v || 'Required';
    },
  },
};
</script>

<style scoped>
.container-centered {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  place-items: center;
}

.container-centered > * {
  min-width: 30vw;
}
</style>
